import { Routes } from '@angular/router';
import { leftyIsLoggedGuard } from '@frontend2/api';
import {
  CASTING_PATH,
  DIRECTORY_REPORT_PATH,
  INFLUENCER_PATH,
} from './constants';

export const appRoutes: Routes = [
  {
    path: INFLUENCER_PATH,
    loadChildren: () =>
      import('./influencer-profile/influencer-profile.module').then(
        (m) => m.EpsonInfluencerProfileRouteModule,
      ),
    canActivate: [leftyIsLoggedGuard],
  },

  {
    path: DIRECTORY_REPORT_PATH,
    loadChildren: () =>
      import('./directory-report/directory-report.module').then(
        (m) => m.EpsonDirectoryReportRouteModule,
      ),
    canActivate: [leftyIsLoggedGuard],
  },
  {
    path: CASTING_PATH,
    loadChildren: () =>
      import('./casting/casting.module').then((m) => m.EpsonCastingRouteModule),
  },
];
